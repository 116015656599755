/**
 *  @module DeviceChecker
 *  @desc Provides Chromecast device checks and attempts best guess at device in use
 */
import config from 'config';

class DeviceChecker {
  constructor() {
    window.deviceDetect = this; // to be removed after testing - allows easier debugging
    this.highSupportChecks = config.bitrateUnCappedDevices;

    this.supportedResolutions = {
      '720p': { width: 1280, height: 720 },
      '1080p': { width: 1920, height: 1080 },
      '2160p': { width: 3840, height: 2160 }
    };
    this.codecs = {
      h264Lvl4Dot1: 'avc1.640028',
      h264Lvl4Dot2: 'avc1.64002A',
      h265: 'hev1.1.6.L150.B0'
    };
    this.context = window.cast && window.cast.framework && window.cast.framework.CastReceiverContext.getInstance();
    this.devices = {
      'ultra4thGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['2160p'],
        framerate: 30
      },
      '4thGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'ultra3rdGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      '3rdGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot2,
        resolution: this.supportedResolutions['1080p'],
        framerate: 60
      },
      '2ndGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      '1stGen': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'builtIn': {
        mimeType: 'video/mp4',
        codec: this.codecs.h265,
        resolution: this.supportedResolutions['2160p'],
        framerate: 30
      },
      'hubMax': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot2,
        resolution: this.supportedResolutions['1080p'],
        framerate: 30
      },
      'hub': {
        mimeType: 'video/mp4',
        codec: this.codecs.h264Lvl4Dot1,
        resolution: this.supportedResolutions['720p'],
        framerate: 30
      }
    };
    this.deviceList = [
      { name: 'ultra4thGen', checkMethod: 'canDeviceSupportUltra4thGen' },
      { name: '4thGen', checkMethod: 'canDeviceSupport4thGen' },
      { name: 'ultra3rdGen', checkMethod: 'canDeviceSupportUltra3rdGen' },
      { name: '3rdGen', checkMethod: 'canDeviceSupport3rdGen' },
      { name: '2ndGen', checkMethod: 'canDeviceSupport2ndGen' },
      { name: '1stGen', checkMethod: 'canDeviceSupport1stGen' },
      { name: 'hubMax', checkMethod: 'canDeviceSupportNestHubMax' },
      { name: 'hub', checkMethod: 'canDeviceSupportNestHub' },
      { name: 'builtIn', checkMethod: 'canDeviceSupportBuiltIn' }
    ];
  }

  allDeviceChecks() {
    const deviceResults = [];
    if (this.highSupportChecks === undefined) {
      this.highSupportChecks = config.bitrateUnCappedDevices;
    }
    const deviceCapabilities = this.context.getDeviceCapabilities();
    const deviceOS = this.getDevicesOSVersion();
    const userAgent = navigator.userAgent.toString();
    this.deviceModelBestGuess = this.getModelDeviceBestGuess();
    deviceResults.push(`Chromecast User Agent ${userAgent}`);
    deviceResults.push(`Chromecast Device Capabilities: ${JSON.stringify(deviceCapabilities, null, 4)}`);
    deviceResults.push(`DeviceOS: ${deviceOS}`);
    deviceResults.push(`Chromecast Device Best Guess: ${this.deviceModelBestGuess}`);
    deviceResults.push(`Chromecast High Support Devices: ${JSON.stringify(this.highSupportChecks, undefined, 4)}`);

    this.deviceList.forEach((deviceObj) => {
      const methodCheck = this[deviceObj.checkMethod]();

      deviceResults.push({
        message: `DEVICE CHECK: ${deviceObj.name} methodCheck: ${deviceObj.checkMethod}: ${methodCheck}`,
        deviceTypeCheck: `${deviceObj.name} - TEST Result:${methodCheck}`
      });
    });

    return deviceResults;
  }

  getModelDeviceBestGuess() {
    return (this.deviceList.find((deviceObj) => {
      return this[deviceObj.checkMethod]();
    }) || {}).name || 'unknown device';
  }

  getCurrentDeviceSupport() {
    const deviceCapabilities = this.context.getDeviceCapabilities();
    return `Device Config Partner List: ${JSON.stringify(config.bitrateUnCappedDevices)} 
    Chromecast Device Capabilities: ${JSON.stringify(deviceCapabilities, null, 4)}\r\n`;
  }

  checkHighBitrateSupport() {
    if (this.canDeviceSupportHDR()) {
      return true;
    }
    if (this.highSupportChecks === undefined) {
      this.highSupportChecks = config.bitrateUnCappedDevices;
    }
    const currentDevice = this.getModelDeviceBestGuess();
    const inHighSupportList = this.highSupportChecks && this.highSupportChecks.includes(currentDevice);
    const deviceObj = this.deviceList.find((deviceObj) => deviceObj.name === currentDevice);

    if (inHighSupportList) {
      return this[deviceObj.checkMethod]();
    }
    return false;
  }

  canDeviceSupportHDR() {
    const deviceCapabilities = this.context.getDeviceCapabilities() || {};
    const { is_hdr_supported: isHdrSupported } = deviceCapabilities;
    return isHdrSupported;
  }

  canDeviceSupport(deviceName) {
    const { mimeType, codec, resolution, framerate } = this.devices[deviceName];
    return this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }

  canDeviceSupportUltra4thGen() {
    const deviceOS = this.getDevicesOSVersion();
    const androidOS = deviceOS.toLowerCase().search('android') >= 0;
    const userAgent = navigator.userAgent.toString();
    const buildOf4thGenUHD = userAgent.toLowerCase().search('stte.230319.008.h1') >= 0;

    return buildOf4thGenUHD || (this.canDeviceSupport('ultra4thGen') && androidOS);
  }

  canDeviceSupport4thGen() {
    const deviceOS = this.getDevicesOSVersion();
    const androidOS = deviceOS.toLowerCase().search('android') >= 0;
    const userAgent = navigator.userAgent.toString();
    const buildOf4thGen = userAgent.toLowerCase().search('stte.230319.008.r1') >= 0;

    return buildOf4thGen || (this.canDeviceSupport('4thGen') && androidOS);
  }

  canDeviceSupportUltra3rdGen() {
    const userAgent = navigator.userAgent.toString();
    const cpuPlatform = userAgent.toLowerCase().search('aarch64') >= 0;

    return cpuPlatform && this.canDeviceSupport('ultra3rdGen');
  }

  canDeviceSupport3rdGen() {
    const userAgent = navigator.userAgent.toString();
    const cpuPlatform = userAgent.toLowerCase().search('armv7l') >= 0;
    const isDeviceChromecastOS = this.getDevicesOSVersion().toLowerCase() === 'chromecast';
    // Prevents built-in tv devices with HDR support from being ID as gen3
    if (this.canDeviceSupportHDR()) {
      return false;
    }

    return isDeviceChromecastOS && cpuPlatform && this.canDeviceSupport('3rdGen');
  }

  canDeviceSupport2ndGen() {
    const { mimeType, codec, resolution, framerate } = this.devices['2ndGen'];
    return (
      this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate) &&
      this.isOSHigherThanOrEqual(this.getDevicesOSVersion(), '1.56.275')
    );
  }

  canDeviceSupport1stGen() {
    return this.canDeviceSupport('1stGen') && this.isOSLowerThanOrEqual(this.getDevicesOSVersion(), '1.36');
  }

  canDeviceSupportNestHubMax() {
    const { mimeType, codec, resolution, framerate } = this.devices.hubMax;
    const touchSupport = this.context.getDeviceCapabilities().touch_input_supported || false;
    return touchSupport && this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }
  canDeviceSupportNestHub() {
    const { mimeType, codec, resolution, framerate } = this.devices.hub;
    const touchSupport = this.context.getDeviceCapabilities().touch_input_supported || false;
    return touchSupport && this.context.canDisplayType(mimeType, codec, resolution.width, resolution.height, framerate);
  }
  canDeviceSupportBuiltIn() {
    return this.canDeviceSupport('builtIn');
  }

  getDevicesOSVersion() {
    const userAgent = navigator.userAgent.toString();
    const userAgentSplit = userAgent.split('/', 8);
    const fullOS = userAgentSplit[userAgentSplit.length - 1];
    return fullOS;
  }

  isOSLowerThanOrEqual(osVersion, expectedOS) {
    const os = osVersion.split('.', 2);
    const expectedOSArray = expectedOS.split('.', 2);
    return Number(os[1]) <= Number(expectedOSArray[1]);
  }
  isOSHigherThanOrEqual(osVersion, expectedOS) {
    const os = osVersion.split('.', 3);
    const expectedOSArray = expectedOS.split('.', 3);
    if (Number(os[1]) === Number(expectedOSArray[1])) {
      return Number(os[2]) >= Number(expectedOSArray[2]);
    }
    return Number(os[1]) > Number(expectedOSArray[1]);
  }
}

const deviceChecker = new DeviceChecker();
export const allDeviceChecks = deviceChecker.allDeviceChecks.bind(deviceChecker);
export const checkHighBitrateSupport = deviceChecker.checkHighBitrateSupport.bind(deviceChecker);
export const getModelDeviceBestGuess = deviceChecker.getModelDeviceBestGuess.bind(deviceChecker);
export const getCurrentDeviceSupport = deviceChecker.getCurrentDeviceSupport.bind(deviceChecker);
